import React from "react";
import { useState, useEffect } from "react";
import styles from "./footer.module.css";
import { FaArrowUpLong } from "react-icons/fa6";
import { FiFacebook } from "react-icons/fi";
import { CgMail } from "react-icons/cg";
import { SlSocialFacebook } from "react-icons/sl";
import { MdOutlineLocalPhone } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { CiLocationOn } from "react-icons/ci";
import logo from "../Images/Logo.svg";
import phone from "../Images/phone.png";
import location from "../Images/location.png";
import email from "../Images/email.png";
import clock from "../Images/clock.png";
import { NavLink, useNavigate } from "react-router-dom";
const phoneNumber = "+91 8734-000008";
const Footer = () => {
  const navigate = useNavigate();
  const handlerNavigate_on_Aboutus_page = () => {
    navigate("/aboutus");
  };
  const handlerNavigate_on_privacy_page = () => {
    navigate("/privacypolicy");
  };
  const handlerNavigate_on_term_and_condition = () => {
    navigate("/term_conditon");
  };

  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    if (window.pageYOffset > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <main className={styles.footerItems}>
          <div className={styles.footerIcons}>
            <a href="https://www.facebook.com/profile.php?id=61550965500434">
              <FiFacebook className={styles.icons} />
            </a>
            <a
              href="https://www.instagram.com/notorious.jtown/?hl=en"
              target="_blank"
            >
              <FaInstagram className={styles.icons} />
            </a>
            {/* <a href="#">
            <FaXTwitter className={styles.icons} />
            </a> */}
          </div>
          <div>
            <a href="#" className={styles.mobile_logo}>
              <img src={logo} className={styles.centerLogo} alt="" />
            </a>
          </div>
          <div className={styles.getDirection}>
            <CiLocationOn className={styles.icons}></CiLocationOn>
            <a href="https://maps.app.goo.gl/akx94acwUv4tvu5b8" target="_blank">
              Get Direction
            </a>
          </div>
        </main>
        <div></div>
        <main className={styles.mobile_view}>
          <a href="#">
            <img src={logo} className={styles.centerLogo} alt="" />
          </a>
        </main>
        <main className={styles.mobile_view}>
          <div className={styles.footerIcons}>
            <a href="#">
              <FiFacebook className={styles.icons} />
            </a>
            <a
              href="https://www.instagram.com/notorious.jtown/?hl=en"
              target="_blank"
            >
              <FaInstagram className={styles.icons} />
            </a>
            <a href="#">
              <FaXTwitter className={styles.icons} />
            </a>
          </div>

          <div className={styles.getDirection}>
            <CiLocationOn className={styles.icons} />
            <a
              href="https://www.google.com/maps?ll=28.600425,77.375919&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=17938124074764798863"
              target="_blank"
            >
              Get Direction
            </a>
          </div>
        </main>
        <div className={styles.footerCenterItems}>
          <div className={styles.footerCenterFirst}>
            <p className={styles.address}>
              <a
                href="https://maps.app.goo.gl/MSuWrgJSMENx9cfx7"
                target="_blank"
              >
                <CiLocationOn />
                5th Floor One Park Side, 334, Shastri Nagar, Lajpat Nagar,
                <br className={styles.break_text}></br> Jalandhar, Punjab 144001
              </a>{" "}
            </p>
            <p>
              <MdOutlineLocalPhone className={styles.icons} />
              &nbsp;
              {/* <a href="">+91 8734-000008</a> */}
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </p>
            <p>
              <CgMail className={styles.icons}></CgMail>
              &nbsp;{" "}
              <a href="mailto:reservations@notorious.co.in">
                reservations@notorious.co.in
              </a>
            </p>
          </div>
          <div className={styles.footerCenterSecond}>
            <span className={styles.address}>
              <FaRegClock className={styles.time_icon} /> &nbsp; Opening Hours,
              Monday to Sunday (12pm to 12 AM)
            </span>
          </div>
        </div>
        <div className={styles.footerEnding}>
          <p className={styles.endItems}>
            <span>&copy; {new Date().getFullYear()} All Right Reserved</span>
            <span className={styles.line}> | </span>
            <span onClick={handlerNavigate_on_Aboutus_page}>The Notorious</span>
            <span className={styles.line}> | </span>
            <span onClick={handlerNavigate_on_privacy_page}>
              Privacy Policy
            </span>
            <span className={styles.line}> | </span>
            <span onClick={handlerNavigate_on_term_and_condition}>
              Terms & Conditions
            </span>
          </p>
          <div
            className={`styles.scroll-to-top-btn ${isVisible ? "visible" : ""}`}
          >
            <button className={styles.scroll_top_btn} onClick={scrollToTop}>
              <FaArrowUpLong />
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
