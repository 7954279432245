import React, { useState, useEffect } from "react";
import styles from "./AboutUsStory.module.css";
import aboutStoryReact3 from "../Images/AboutRec3.png";
import axios from "axios";
import { ApiBaseUrl, IMAGE_URL } from "../../config/constant";

function AboutUsStory({ storyBehindNotorious }) {
  return (
    <>
      <div className={styles.about_by_main_container}>
        <div className="container">
          <div className={styles.about_by_mid_container}>
            <h1 className={styles.aboutLeftText_}>
              <span>
                {storyBehindNotorious &&
                  storyBehindNotorious[0]?.title &&
                  storyBehindNotorious[0].title.split(" ")[0]}
              </span>{" "}
              {storyBehindNotorious &&
                storyBehindNotorious[0]?.title &&
                storyBehindNotorious[0].title.split(" ")[1]}{" "}
              {storyBehindNotorious &&
                storyBehindNotorious[0]?.title &&
                storyBehindNotorious[0].title.split(" ")[2]}
            </h1>
            <div
              className={styles.about_by_Paragraph}
              dangerouslySetInnerHTML={{
                __html:
                  storyBehindNotorious && storyBehindNotorious[0]?.description,
              }}
            />
          </div>
          <div className={styles.about_notorious_mid_container}>
            <div className={styles.about_notorious_pic}>
              <img
                src={
                  storyBehindNotorious && storyBehindNotorious[0].image
                    ? IMAGE_URL + storyBehindNotorious[0].image
                    : aboutStoryReact3
                }
                alt="image"
                className={styles.about_img}
              />
            </div>
            <div className={styles.about_notorious_paragraph}>
              <p className={styles.about_notorious_paragraph1}>
                As you ascend to the open rooftop, you'll find yourself in a
                world inspired by botanical greenhouses and luxurious cabanas.
                The space breathes with life, adorned in pastel hues that create
                an air of sophistication. Highlighters accentuate long seating
                benches, while intricate grove details on granite tables add
                texture and depth, providing the perfect backdrop for solid
                rustic pastel cutlery.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsStory;
