import React,{useState} from "react";
import rectangle from "./Images/rectangle.png";
import styles from './HomeForm.module.css'
import axios from "axios";
import { ApiBaseUrl } from "../config/constant";

const HomeForm = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
    setMobileNumberError('');
  };

  const validateMobileNumber = (number) => {
    // A simple validation for a 10-digit mobile number
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(number);
  };

  const postMobileNumberData = async () => {
    try {
      const response = await axios.post(
        ApiBaseUrl + 'home/subscribe',
        {
          mobileNumber: mobileNumber,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      setMobileNumberError('Already Subscribed!');  
      alert('Already Subscribed!');

    }
  };

  const handleSubscribe = async () => {
    if (!mobileNumber) {
      setMobileNumberError('Please enter your mobile number');
    } else if (!validateMobileNumber(mobileNumber)) {
      setMobileNumberError('Please your enter a valid 10-digit mobile number');
    } else {
      try {
        const response = await postMobileNumberData();
        console.log(response,"repomse")
      } catch (error) {
        console.error('Error:', error.response);
      }
      setMobileNumber('');
      setMobileNumberError('');
    }
  };
  return (
    <div className={styles.outerContainer}>
      <div className={styles.innerContainer}>
        <div className={styles.heroShade}>
          <div className={styles.rectangleOutline}>
            <img src={rectangle} alt="" />
          </div>
          <div className={styles.HomeFormText}>
            <p> Thank you for choosing us!</p>
            <p>We're excited to host you and make your experience unforgettable!</p>
            <p>We look forward to hosting you.</p>
            <form  className={styles.HomeFormInput_home} autoComplete="off">
              <input
                placeholder="Enter Mobile Number"
                className={styles.HomeFormInputField_home}
                type="tel" value={mobileNumber} onChange={handleMobileNumberChange} 
              />
              <button style={{cursor:"pointer", zIndex:"99"}} className={styles.HomeFormBtn_home}  type="button" onClick={handleSubscribe}>Submit</button>
              
            </form>
            {mobileNumberError && <p className={styles.error} id="subscribe-email">{mobileNumberError}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeForm;
