import styles from "./GalleryPage.module.css";
import Footer from "../footer/Footer";
import Header from "../header/Header";
// import maskgroup from "/images/Maskgroup.png";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import React, { useRef, useEffect, useState } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import Rec2 from "../Images/Rec2.png";
import { ApiBaseUrl, IMAGE_URL } from "../../config/constant";
import axios from "axios";

const Fancybox = ({ children }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current;
    const delegate = "[data-fancybox='images']";
    const options = {
      arrows: true,
      infobar: false,
      loop: true,
      buttons: ["slideShow", "fullScreen", "thumbs", "close"],
      animationEffect: "fade",
      transitionEffect: "slide",
    };
    NativeFancybox.bind(container, delegate, options);
    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  }, []);
  return <div ref={containerRef}>{children}</div>;
};

function GalleryModuale() {
  const [galleryImages, setGalleryImages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${ApiBaseUrl}/home/images`
        );
        setGalleryImages(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);
  const [isImageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <>
      <Header />
      {/* <div className="container-fluid">
        <div className={styles.main_container}>
          <div className={styles.event_child_container}>
       
            <div className={styles.my_event_picture}>
              
              <img
                src={process.env.PUBLIC_URL+"/images/Rec2.png"}
                alt="EventPage"
                className={styles.image_event_main_conatiner}
                loading="lazy"
              />
            </div>
            <div className={styles.child_container}>
              <div className={styles.notorious_event_page_button}>
                <h1 className={styles.aboutLeftText_events_}>
                  <span>About</span> Gallery
                </h1>
                <div className={styles.event_Paragraph}>
                  <p>
                    'Notorious' isn't just a Rooftop Resto Bar and speakeasy;
                    it's a journey through time and design.
                  </p>
                </div>
            
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className={`container-fluid ${styles.main_container_gallery}`}>
        <div className="container">
          <div className={styles.images_of_notorious}>
            <span>Explore</span> our Gallery
          </div>
          <div className="row">
            <div className={`col-md-12 ${styles.about_us_scroll_container}`}>
              <div className={styles.scrollContent}>
                <Fancybox>
                  <div className="fancy-gallery mb-5">
                    <div className="row">
                      {/* <div className="col-md-4">
      <a
        href="../images/Epicalparty8.jpg"
        className="mb-4 col-6 col-sm-4 img-fluid"
        data-fancybox="images"
        data-caption="Image 9"
      >
        <img
          className={styles.rounded}
          src="../images/Epicalparty8.jpg"
          alt="Image 9"
        />
      </a>
    </div> */}
                      {galleryImages &&
                        galleryImages.map((image, index) => (
                          <div className="col-md-4" key={index}>
                            <a
                              href="../images/Epicalparty8.jpg"
                              className="mb-4 col-6 col-sm-4 img-fluid"
                              data-fancybox="images"
                              data-caption="Image 9"
                            >
                              <img
                                className={styles.rounded}
                                src={IMAGE_URL + image.image}
                                alt="Image 9"
                              />
                            </a>
                          </div>
                        ))}

                      {/* <a
                        href="../../../public/images/Maskgroup.png"
                        className="mb-4 col-6 col-sm-4 img-fluid"
                        data-fancybox="images"
                        data-caption="Image 2"
                      >
                        <img
                          className="rounded"
                          src={maskgroup}
                          alt="Image 1"
                        />
                      </a>
                      <a
                       href="../../../public/images/Maskgroup.png"
                        className="mb-4 col-6 col-sm-4 img-fluid"
                        data-fancybox="images"
                        data-caption="Image 3"
                      >
                       <img
                          className="rounded"
                          src={maskgroup}
                          alt="Image 1"
                        />
                      </a>
                      <a
                       href="../../../public/images/Maskgroup.png"
                        className="mb-4 col-6 col-sm-4 img-fluid"
                        data-fancybox="images"
                        data-caption="Image 3"
                      >
                       <img
                          className="rounded"
                          src={maskgroup}
                          alt="Image 1"
                        />
                      </a>
                      <a
                       href="../../../public/images/Maskgroup.png"
                        className="mb-4 col-6 col-sm-4 img-fluid"
                        data-fancybox="images"
                        data-caption="Image 4"
                      >
                       <img
                          className="rounded"
                          src={maskgroup}
                          alt="Image 1"
                        />
                      </a> */}
                      {/* Add more images as needed */}
                    </div>
                  </div>
                </Fancybox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GalleryModuale;
