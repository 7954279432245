import React, { useState, useEffect } from "react";
import styles from "./organizeEvent.module.css";
import maskgroup from "./Images/Maskgroup.png";
// import scrollimg1 from "/images/scrollimg1.png";
import scrollimg2 from "./Images/scrollimg2.png";
// import rectangle18 from "/galleryimages/Rectangle18.png";
// import rectangle24 from "/galleryimages/Rectangle24.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiBaseUrl, IMAGE_URL } from "../config/constant";
import { formatEventDate } from "./common";

const OrganizeEvent = () => {
  const navigate = useNavigate();
  const handleRedirectOnEvent = () => {
    navigate("/events");
  };
  const [event, setEvent] = useState(null);
  const [heading, setHeading] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiBaseUrl + "events");
        console.log(response);
        setEvent(response.data.events);
        setHeading(response.data.heading);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className={`container-fluid ${styles.aboutOrganizeEventContainer}`}>
      <div className="container">
        <div className={styles.my_heading_container}>
          <div className={styles.Heading}>
            <h1>{heading && heading[0].title}</h1>

            <div
              dangerouslySetInnerHTML={{
                __html: heading && heading[0]?.description,
              }}
            />

            <div className={styles.heroHeadingBtn}>
              <button
                type="button"
                className={styles.resBtn}
                onClick={handleRedirectOnEvent}
              >
                View All
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {event &&
              event.slice(0, 1).map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className={styles.leftContainer}>
                    <img
                      src={
                        item.image
                          ? IMAGE_URL + item.image
                          : process.env.PUBLIC_URL + maskgroup
                      }
                      alt="img"
                    />
                    {/* <img src={maskgroup} alt="" /> */}
                    <div>
                      <h2> {formatEventDate(item.eventDate)}</h2>
                      <h3>{item.title}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      <Link to="/events">
                        <a href="">Read More....</a>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            <div class="col-md-6">
              <div className={styles.OrganizeEvent_container}>
                <div class="row">
                  {event &&
                    event.map((item, index) => (
                      <div className="col-md-6" key={index}>
                        <div className={styles.leftContainer}>
                          <img
                            src={
                              item.image
                                ? IMAGE_URL + item.image
                                : process.env.PUBLIC_URL + maskgroup
                            }
                            alt="img"
                          />
                          <div>
                            <h2> {formatEventDate(item.eventDate)}</h2>
                            <h3>{item.title}</h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                            <Link to="/events">
                              <a href="">Read More....</a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizeEvent;
