import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import styles from "./Events.module.css";
// import nlogo from "/images/nlogo.svg";
import EventBy from "./EventBy";
import Footer from "../footer/Footer";
import Rec2 from "../Images/Rec2.png";
import PopUPModal from "../header/PopUpModal";
import { Link } from "react-router-dom";

function Events() {
  const [modalOpen, setPopUpModalOpen] = useState({
    thermalModal: false,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className={styles.main_container}>
          <div className={styles.event_child_container}>
            <div className={styles.my_event_picture}>
              <img
                src={process.env.PUBLIC_URL + "/images/Rec2.png"}
                alt="EventPage"
                className={styles.image_event_main_conatiner}
              />
            </div>
            <div className={styles.child_container}>
              <div className={styles.notorious_event_page_button}>
                <h1 className={styles.aboutLeftText_events_}>
                  Notorious<span> Events</span>
                </h1>
                <div className={styles.event_Paragraph}>
                  <p>
                    'Notorious' isn't just a Rooftop Resto Bar and speakeasy;
                    it's a journey through time and design.
                  </p>
                </div>
                <div className={styles.event_page_button}>
                  <button
                    className={styles.resBtn}
                    type="button"
                    onClick={() => setPopUpModalOpen({ thermalModal: true })}
                  >
                    {/* Book & Event */}
                    Reservation
                  </button>
                  <Link to="/contactus">
                    <button className={styles.connect_Btn} type="button">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EventBy />
      <Footer />
      <PopUPModal modalOpen={modalOpen} setPopUpModalOpen={setPopUpModalOpen} />
    </>
  );
}

export default Events;
