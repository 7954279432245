import React, { useState, useEffect } from "react";
import styles from "./home.module.css";
import heroLogo from "./Images/heroLogo.svg";
// import aboutimg from "/images/aboutimg.svg";
import outline from "./Images/outline.png";
import nlogo from "./Images/nlogo.svg";
import Header from "./header/Header";
import OurMenu from "./OurMenu";
import Gallery from "./Gallery";
import OrganizeEvent from "./OrganizeEvent";
import HomeForm from "./HomeForm";
import Footer from "./footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import PopUPModal from "./header/PopUpModal";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { ApiBaseUrl, IMAGE_URL } from "../config/constant";
import Crousel from "./Carousles";

import Gallerymobile from "./Gallerymobile";
import "./mobile_gallery.css";
const Home = () => {
  const [modalOpen, setPopUpModalOpen] = useState({
    thermalModal: false,
  });
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/aboutus");
  };
  const handleConatctUs = () => {
    navigate("/contactus");
  };
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiBaseUrl + "home/aboutNotorious");
        setData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header></Header>

      <div className={styles.outerContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.heroShade}>
            <div className={styles.heroHeading}>
              {/* <img src={heroLogo} alt="" /> */}
              {/* <p className={styles.heroText}>
                'Notorious' isn't just a Rooftop Resto Bar and Speakeasy; it's a
                journey through time and design.
              </p> */}
            </div>
            <div className={styles.heroHeadingBtn}>
              <button
                type="button"
                className={styles.resBtn}
                onClick={() => setPopUpModalOpen({ thermalModal: true })}
              >
                Reservation
              </button>
              <Link to="/contactus">
                <button type="button" className={styles.resBtn}>
                  Contact us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <section className={styles.about}>
        <div className={styles.outerContainer}>
          <section className={styles.aboutSection} id="about_us">
            <img
              src={
                data && data[0].image
                  ? IMAGE_URL + data[0].image
                  : process.env.PUBLIC_URL + nlogo
              }
              alt=""
              className={styles.nLogo}
            />
            <div className={styles.aboutLeft}>
              <div className={styles.aboutLeftShade}></div>
              <img
                src={process.env.PUBLIC_URL + outline}
                alt=""
                className={styles.outline}
              />
              <div className={styles.aboutText}>
                <h1 className={styles.aboutLeftText}>
                  <span>
                    {data && data[0]?.title && data[0].title.split(" ")[0]}
                  </span>{" "}
                  {data && data[0]?.title && data[0].title.split(" ")[1]}
                </h1>
                <p
                  className={styles.aboutLeftContent}
                  dangerouslySetInnerHTML={{
                    __html: data && data[0]?.description,
                  }}
                ></p>

                <div className={styles.aboutBtn}>
                  <button className={styles.menuBtn} onClick={handleRedirect}>
                    Read More
                  </button>
                </div>
              </div>
            </div>
            <div className={styles.aboutRight}>
              {/* <img src={process.env.PUBLIC_URL+'/images/aboutimg.svg'} alt="" /> */}
              <div className={styles.aboutRightInside}>
                <div className={styles.aboutShade}></div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <Crousel />

      <OurMenu />
      <></>
      <Gallery />
      <div className="mobile_gallery_view">
        <div className="centerContent">
          <h1 className="Heading">
            <span>See Our Gallery</span>
            <br />
            Specialized Photos & Videos We Have
          </h1>
          <p className="textContent">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem.
          </p>
          <button
            className="visitBtn"
            onClick={() => {
              navigate("/gallery");
            }}
          >
            Visit Gallery
          </button>
        </div>
        <Gallerymobile></Gallerymobile>
      </div>

      <OrganizeEvent />
      <HomeForm />
      <Footer />
      <PopUPModal modalOpen={modalOpen} setPopUpModalOpen={setPopUpModalOpen} />
    </>
  );
};

export default Home;
