import React from "react";
import Home from "./components/Home";
import Events from "./components/Events/Events";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./components/AboutUs/AboutUs";
import "./App.css";
import ContactUs from "./components/Contactus/ContactUs";
import GalleryModuale from "./components/Gallery Page/GalleryPage";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";
import TermConditionFile from "./components/footer/Term&ConditionFile";

const App = () => {
  return (
    <>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/events" element={<Events />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/gallery" element={<GalleryModuale />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
          <Route path="/term_conditon" element={<TermConditionFile/>} />
        </Routes>
      </div>
    </>
  );
};

export default App;
