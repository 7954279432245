import React, { useEffect } from "react";
import Header from "../header/Header";
import Footer from "./Footer";
import styles from "./footer.module.css";

function TermConditionFile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className={`container-fluid ${styles.my_privacy_policy_main_class}`}>
        <div className="container">
          <div className="row">
            <div className={styles.my_main_content}>
              <div className={styles.my_privacy_policy_paragraph}>
                <p className={styles.main_paragraph}>
                  Terms & Conditions for <span> Notorious</span>
                </p>
                <span className={styles.privacy_policy_date}>
                  Last Updated: 20-December-2023
                </span>
                <p className={styles.main_paragraph1}>
                  Welcome to <strong> Notorious</strong>. By accessing our
                  website, making reservations, or using our services, you agree
                  to comply with and be bound by the following Terms &
                  Conditions. Please read these terms carefully before using our
                  services.
                </p>
              </div>
              <div className={styles.term_and_condition_gap_div}>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    1. Reservation and Seating Policy:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      1.1 Reservations are subject to availability and
                      confirmation.
                    </span>
                    <span className={styles.reservation_child_content}>
                      1.2 We reserve the right to allocate seating based on
                      availability and party size.
                    </span>
                    <span className={styles.reservation_child_content}>
                      1.3 Please arrive on time for your reservation. We may
                      release your table if you are more than 15 minutes late
                      without notice.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    2. Age Restriction:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      2.1 Our bar area is restricted to individuals aged 18+ and
                      above.
                    </span>
                    <span className={styles.reservation_child_content}>
                      2.2 Guests may be required to present a valid ID for age
                      verification.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    3. Code of Conduct:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      3.1 We expect all guests to behave respectfully towards
                      staff and fellow patrons.
                    </span>
                    <span className={styles.reservation_child_content}>
                      3.2 Any form of harassment, discrimination, or disruptive
                      behavior will not be tolerated, and we reserve the right
                      to refuse service or ask individuals to leave the
                      premises.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    4. Payment Policy:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      4.1 Payment is required at the time of service.
                    </span>
                    <span className={styles.reservation_child_content}>
                      4.2 We accept cash, credit cards, and other forms of
                      payment as indicated.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    5. Menu and Pricing:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      5.1 Menu items and prices are subject to change without
                      notice.
                    </span>
                    <span className={styles.reservation_child_content}>
                      5.2 We strive to provide accurate and up-to-date
                      information, but errors may occur. In such cases, we
                      reserve the right to correct any errors and update
                      information as needed.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    6. Reservation Cancellation:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      6.1 Please notify us promptly if you need to cancel or
                      modify a reservation.
                    </span>
                    <span className={styles.reservation_child_content}>
                      6.2 Late cancellations or no-shows may be subject to a
                      fee.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    7. Lost or Stolen Items:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      7.1 We are not responsible for lost or stolen items on our
                      premises.
                    </span>
                    <span className={styles.reservation_child_content}>
                      7.2 Please take care of your personal belongings.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    8. Intellectual Property:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      8.1 All content on our website, including but not limited
                      to text, graphics, logos, and images, is the property of
                      <strong> Notorious</strong> and is protected by copyright laws.
                    </span>
                    <span className={styles.reservation_child_content}>
                      8.2 You may not use our intellectual property without our
                      express written consent.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>
                    9. Changes to Terms & Conditions:
                  </p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      9.1 We reserve the right to update and modify these Terms
                      & Conditions at any time. The latest version will be
                      posted on our website.
                    </span>
                    <span className={styles.reservation_child_content}>
                      9.2 Your continued use of our services after changes have
                      been made constitutes acceptance of those changes.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.information_collect}>10. Contact Us:</p>
                  <div className={styles.reservation_content_div}>
                    <span className={styles.reservation_child_content}>
                      If you have any questions or concerns about our Terms &
                      Conditions, please contact us at
                      <strong> reservations@notorious.co.in</strong>.
                    </span>
                  </div>
                </div>
                <div className={styles.my_privacy_policy_paragraph_information}>
                  <p className={styles.main_paragraph1}>
                    Thank you for choosing <strong>Notorious</strong>. Enjoy
                    your experience with us!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermConditionFile;
