export const disabledDate = (current) => {
    return current && current < new Date();
  };

  export const formatEventDate = (eventDate)=> {
    const dateObject = new Date(eventDate);
  
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    }).format(dateObject);
  }
  export const disabledHours = (selectedDate) => {
    if (selectedDate) {
      // If the selected date is today, disable past hours
      if (selectedDate.isSame(new Date(), 'day')) {
        return Array.from({ length: new Date().getHours() }, (_, i) => i);
      }
    }
    // Otherwise, no hours are disabled
    return [];
  };

 export const disabledMinutes = (selectedHour ,selectedDate,selectedTime) => {
    if (selectedDate && selectedTime) {
      // If the selected date and time are today and in the past, disable past minutes
      if (
        selectedDate.isSame(new Date(), 'day') &&
        selectedHour === new Date().getHours()
      ) {
        return Array.from({ length: new Date().getMinutes() }, (_, i) => i);
      }
    }
    // Otherwise, no minutes are disabled
    return [];
  };

