// PopUPModal.js
import React, { useState } from "react";
import { Modal } from "antd";
import { DatePicker, TimePicker } from "antd";
import styles from "./PopUpModal.module.css";
import ThankuPage from "./ThankuPage";
import { validateForm } from "../Validations/tableReservation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { disabledDate, disabledHours, disabledMinutes } from "../common";
import axios from "axios";
import { ApiBaseUrl } from "../../config/constant";
// import frame11 from '../../../public/images/Frame 17.png'

const PopUPModal = ({ modalOpen, setPopUpModalOpen }) => {
  const [thankuModalOpen, setThankuPageOpen] = useState({
    myThankuModal: false,
  });

  // for Open modal code
  const handleCancel = () => {
    setPopUpModalOpen((prev) => ({
      ...prev,
      thermalModal: false,
    }));
  };

  // for opening ThankuPage modal
  const handleProceed = () => {
    setPopUpModalOpen((prev) => ({
      ...prev,
      thermalModal: false,
    }));
    setThankuPageOpen({ myThankuModal: true });
  };

  // started by Md

  const [reserveTable, setReserveTable] = useState("");
  const [formData, setFormData] = useState({});

  const [formErrors, setFormErrors] = useState({});
  const [checkBlur, setCheckBlur] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormErrors("");
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDate = (e, date) => {
    setFormErrors("");
    setFormData((prevData) => ({
      ...prevData,
      bookingDate: date,
    }));
    const d = date.split("-");
    setFormData((prevData) => ({
      ...prevData,
      bookingDate_: `${d[2]}-${d[1]}-${d[0]}`,
    }));
  };
  const handleTime = (e, time) => {
    setFormErrors("");
    setFormData((prevData) => ({
      ...prevData,
      time: time,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    
    validateForm(formData, checkBlur)
      .then(() => {
        axios
          .post(ApiBaseUrl + "reservation", formData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.data) {
              setReserveTable(response.data);
              setFormData([]);
              toast.success("Form submitted successfully!");
              handleProceed();
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              // Handle 400 Bad Request error
              // You can access the error details from error.response.data
              setFormErrors((pre)=>({
                ...pre,
                formError : error?.response?.data?.error
              }))
              toast.error(error.response.data.error);
              // console.log(error.response.data.error);
            } else {
              // Handle other types of errors
              toast.error("Form submission failed!");
              // console.error(error);
            }
          });
      })
      .catch((errors) => {
        // Handle validation errors and update the state with error messages
        const newErrors = {};
        errors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
        setFormErrors(newErrors);
  
        toast.error("Form submission failed!");
        console.error(errors);
      });
  };
  

  //end by md
  return (
    <>
      <Modal
        className={styles.tharmal_modal}
        open={modalOpen?.thermalModal}
        // title="Table Reservation"
        width={600}
        maskClosable={false}
        wrapClassName={styles.modalWrapper}
        footer={null}
        onCancel={handleCancel}
        zIndex={9999}
      >
        <div className={styles.my_slider_page}>
          <div className={styles.my_input_form} id="registeration_form">
            {/* <div className={styles.my_slider_nlog_img1_frame}>
              <img src={frame11} alt="Notorious" />
            </div> */}
            <h1 className={styles.aboutLeftText}>
              <span>NOTORIOUS</span>
            </h1>
            <div className={styles.reserve_seat}>
              <span>Table Reservation</span>
            </div>
            <form
              action=""
              className={styles.HomeFormInput_slide}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                placeholder="* Full Name"
                className={`${styles.HomeFormInputField_slide} ${
                  formErrors.fullName ? styles.active : ""
                }`}
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                onBlur={() => setCheckBlur(true)}
              />
              {formErrors.fullName && (
                <div className={styles.error}>{formErrors.fullName}</div>
              )}

              <input
                type="number"
                placeholder="* Mobile Number"
                className={`${styles.HomeFormInputField_slide} ${
                  formErrors.mobileNumber ? styles.active : ""
                }`}
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                onBlur={() => setCheckBlur(true)}
              />
              {formErrors.mobileNumber && (
                <div className={styles.error}>{formErrors.mobileNumber}</div>
              )}

              <input
                type="email"
                placeholder="* Enter Email Address"
                className={`${styles.HomeFormInputField_slide} ${
                  formErrors.email ? styles.active : ""
                }`}
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={() => setCheckBlur(true)}
              />
              {formErrors.email && (
                <div className={styles.error}>{formErrors.email}</div>
              )}

              <input
                type="number"
                placeholder="* No Of Members"
                className={`${styles.HomeFormInputField_slide} ${
                  formErrors.noOfMembers ? styles.active : ""
                }`}
                name="noOfMembers"
                value={formData.noOfMembers}
                onChange={handleChange}
                onBlur={() => setCheckBlur(true)}
              />
              {formErrors.noOfMembers && (
                <div className={styles.error}>{formErrors.noOfMembers}</div>
              )}

              <input
                type="text"
                placeholder="* Occasion"
                className={`${styles.HomeFormInputField_slide} ${
                  formErrors.occasion ? styles.active : ""
                }`}
                name="occasion"
                value={formData.occasion}
                onChange={handleChange}
                onBlur={() => setCheckBlur(true)}
              />
              {formErrors.occasion && (
                <div className={styles.error}>{formErrors.occasion}</div>
              )}
              <DatePicker
                name="bookingDate"
                className={`${styles.HomeFormInputField_slide} ${
                  styles.datePicker
                }  ${formErrors.bookingDate ? styles.active : ""}`}
                disabledDate={disabledDate}
                placeholder="* Select Date (DD/MM/YYYY)"
                onChange={handleDate}
                format="DD-MM-YYYY"
              />

              {formErrors.bookingDate && (
                <div className={styles.error}>{formErrors.bookingDate}</div>
              )}
              <TimePicker
                name="time"
                className={`${styles.HomeFormInputField_slide} ${
                  styles.timePicker
                } ${formErrors.time ? styles.active : ""}`}
                placeholder="* Select Time"
                onChange={handleTime}
                format="h:mm A"
                minuteStep={15}
              />
              {formErrors.time && (
                <div className={styles.error}>{formErrors.time}</div>
              )}

              <textarea
                placeholder="Write Any Message"
                name="message"
                className={styles.HomeFormInputField_slide}
                onChange={handleChange}
              />
                {formErrors?.formError && (<div className={styles.error}>{formErrors.formError}</div>)}
              <div className={styles.proceed_to_next}>
                <button
                  type="button"
                  className={styles.proceed_button}
                  // onClick={handleProceed}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </div>
      </Modal>
      <ThankuPage
        thankuModalOpen={thankuModalOpen}
        setThankuPageOpen={setThankuPageOpen}
        reserveTable={reserveTable}
      />
    </>
  );
};

export default PopUPModal;
