import React, { useState, useEffect, useRef } from "react";
import { Modal as BootstrapModal } from "bootstrap";
import styles from "./AboutOrganizeEvent.module.css";
import { Carousel } from "react-bootstrap";
// import maskgroup from "/images/Maskgroup.png";
// import cabanasVideo from "../Videos/Notorious Final Draft.mp4";
// import speakEasyVideo from "../Videos/Speakeasy 2nd draft.mp4";
// import scrollimg1 from "../../../public/images/MaskImage1.png";
// import scrollimg2 from "../../../public/images/MaskImage2.png";
// import scrollimg3 from "../../../public/images/MaskImage3.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import speakEasyImage1 from '../Images/SpeakEasy1.jpg'
// import speakEasyImage2 from '../Images/SpeakEasy2.jpg'
// import speakEasyImage3 from '../Images/SpeakEasy3.jpg'
// import speakEasyImage4 from '../Images/SpeakEasy4.jpg'
// import speakEasyImage5 from '../Images/SpeakEasy5.jpg'
// import speakEasyImage6 from '../Images/Speakeasy6.jpg'
// import cabanasImages1 from '../Images/_ZC_1009 Cabanas1.jpg'
// import cabanasImages2 from '../Images/_ZC_1038 cabanas2.jpg'
// import cabanasImages3 from '../Images/_ZC_1128 cabanas3.jpg'
// import cabanasImages4 from '../Images/_ZC_1195 Cabanas4.jpg'
// import cabanasImages5 from '../Images/_ZC_1382 Cabanas5.jpg'
// import outDoorImage1 from '../Images/outDoorImage1.jpg'
// import outDoorImage2 from '../Images/outDoorImage2.jpg'
// import outDoorImage3 from '../Images/outDoorImage3.jpg'
// import outDoorImage4 from '../Images/outDoorImage4.jpg'
// import outDoorImage5 from '../Images/outDoorImage5.jpg'
import { IMAGE_URL } from "../../config/constant";
import { Fancybox } from "@fancyapps/ui";
import "bootstrap/dist/css/bootstrap.min.css";
import "./slider.css";
import Lightbox from "react-images";
const AboutOrganizeEvent = ({ cabanas, outdoor, speakeasy }) => {
  const [current_img, setcurrent_img] = useState("");
  const [outdoor_img, setoutdoor_img] = useState("");
  const [cabana_img, setcabana_img] = useState("");

  const [show, setshow] = useState(false);
  const handle_show = () => {
    // Assuming you are using Bootstrap 5
    const modal = new BootstrapModal(document.getElementById("yourModalId"));
    modal.show();
  };

  const handle_outdoor = () => {
    const modal = new BootstrapModal(document.getElementById("out_door"));
    console.log("outdoor_img", outdoor_img);
    modal.show();
  };
  const handle_speaksy = () => {
    const modal = new BootstrapModal(document.getElementById("speaksy_model"));
    console.log("outdoor_img", outdoor_img);
    modal.show();
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    const lineHeight = parseInt(
      window.getComputedStyle(contentElement).lineHeight
    );
    const maxHeight = lineHeight * 3;

    setIsExpanded(contentElement.clientHeight > maxHeight);
  }, []);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const contentClass = `content ${isExpanded ? "expanded" : ""}`;
  return (
    <>
      <div className={`container-fluid ${styles.aboutOrganizeEventContainer}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <div className={styles.Heading}>
                  <div>
                    <h1>Cabana's</h1>
                  </div>
                  <div>
                    {/* //read more read less functionality */}
                    <div className={styles.read_more_less}>
                      <div ref={contentRef} className={contentClass}>
                        <p>
                          {!isExpanded &&
                            "The heart of 'Notorious' lies in its three distinct cabanas, cocooned by lush landscapes, vertical gardens, and water bodies that form graceful strips. A small private dining ..."}
                          {isExpanded &&
                            "The heart of 'Notorious' lies in its three distinct cabanas, cocooned by lush landscapes, vertical gardens, and water bodies that form graceful strips. A small private dining area and an outdoor bar, adorned with vaulted arches and brass shelving details, break the spatial flow of the cabanas. A brass skylight crowns the bar, casting a gentle glow over the atmosphere. A central courtyard, cocooned by planters, features built-in seating, creating a serene retreat. Walkways with jute fabric shading above connect these spaces, ensuring a seamless flow. "}
                          {!isExpanded && (
                            <button
                              onClick={toggleReadMore}
                              className={styles.read_more_read_less}
                            >
                              Read More
                            </button>
                          )}
                          {isExpanded && (
                            <button
                              onClick={toggleReadMore}
                              className={styles.read_more_read_less}
                            >
                              Read Less
                            </button>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {cabanas &&
                cabanas.map((item, index) => (
                  <div key={index} className={styles.leftContainer}>
                    {item.videoUrl ? (
                      <video controls className={styles.left_conatiner_video}>
                        <source src={item.videoUrl} type="video/mp4" />
                      </video>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
            </div>
            <div className="col-md-6">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {cabanas && cabanas.length > 0 && (
                    <>
                      {cabanas.map(
                        (item, index) =>
                          item?.image && (
                            <div
                              key={index}
                              className={`carousel-item ${
                                index === 0 ? "active" : ""
                              }`}
                            >
                              <img
                                className="d-block w-100"
                                src={
                                  item?.image ? `${IMAGE_URL}${item.image}` : ""
                                }
                                alt="image"
                                onClick={() => {
                                  setcurrent_img(item.image);
                                  handle_show();
                                }}
                              />
                            </div>
                          )
                      )}
                    </>
                  )}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        visible={show}
        id="yourModalId"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Cabana's
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                id="canbana_slider"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {cabanas && cabanas.length > 0 && (
                    <>
                      <div key={`indes`} className={`carousel-item`}>
                        <img
                          className={styles.my_image_mask}
                          src={IMAGE_URL + current_img}
                          alt="image"
                        />
                      </div>
                      {cabanas.map(
                        (item, index) =>
                          item?.image && (
                            <div
                              key={index}
                              className={`carousel-item ${
                                index === 0 ? "active" : ""
                              }`}
                            >
                              <img
                                className={styles.my_image_mask}
                                src={IMAGE_URL + item.image}
                                alt="image"
                              />
                            </div>
                          )
                      )}
                    </>
                  )}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#canbana_slider"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#canbana_slider"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>

              {/* Content of your modal */}
            </div>
          </div>
        </div>
      </div>

      {/* outdoor   */}
      <div className={styles.aboutOrganizeEventConatiner_1}>
        <div className="container">
          <div className={styles.Heading}>
            <div>
              <h1>Metal Structure Walkway View</h1>
            </div>
            <div className={styles.read_more_less}>
              <div ref={contentRef} className={contentClass}>
                <p>
                  {!isExpanded &&
                    "Metal members give life to architectural elements, while the hut-shaped cabanas boast glass cutouts, inviting ..."}
                  {isExpanded &&
                    "Metal members give life to architectural elements, while the hut-shaped cabanas boast glass cutouts, inviting natural light to dance within. The result is a serene, botanical paradise, where every corner is a visual masterpiece."}
                  {!isExpanded && (
                    <button
                      onClick={toggleReadMore}
                      className={styles.read_more_read_less_ourdoor}
                    >
                      Read More
                    </button>
                  )}
                  {isExpanded && (
                    <button
                      onClick={toggleReadMore}
                      className={styles.read_more_read_less_ourdoor}
                    >
                      Read Less
                    </button>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {outdoor &&
                outdoor.map((item, index) => (
                  <div key={index} className="col-md-6">
                    <div className={styles.leftContainer}>
                      {item.videoUrl ? (
                        <video controls className={styles.left_conatiner_video}>
                          <source src={item.videoUrl} type="video/mp4" />
                        </video>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))}
              <div className={`col-md-6 ${styles.about_us_scroll_container}`}>
                <div class="row">
                  <div
                    id="out_door_controls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-inner">
                      {outdoor && outdoor.length > 0 && (
                        <>
                          {outdoor.map(
                            (item, index) =>
                              item?.image && (
                                <div
                                  key={index}
                                  className={`carousel-item ${
                                    index === 0 ? "active" : ""
                                  }`}
                                >
                                  <img
                                    className="d-block w-100"
                                    src={
                                      item && item.image
                                        ? `${IMAGE_URL}${item.image}`
                                        : ""
                                    }
                                    alt="image"
                                    onClick={() => {
                                      setoutdoor_img(item.image);
                                      handle_outdoor();
                                    }}
                                  />
                                </div>
                              )
                          )}
                        </>
                      )}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#out_door_controls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#out_door_controls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                  {/* {outdoor && outdoor.length > 0 && (
              <Carousel autoplay slidesToShow={1.2} centerMode responsive={[
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1.5,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1.5,
                    },
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1.5,
                    },
                },
            ]}>
                {outdoor.map((item, index) => (
                  <div key={index} >
                    <div className={styles.scrollContent}>
                      <div className={styles.card} onClick={handleImagePopup}>
                        <Image
                          className={styles.my_image_mask}
                          src={
                            item && item.image
                              ? `${IMAGE_URL}${item.image}`
                              : outDoorImage1
                          }
                          
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>
            )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        visible={show}
        id="out_door"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Outdoor</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                id="out_door_modal"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {outdoor && outdoor.length > 0 && (
                    <>
                      <div key={`index`} className={`carousel-item active`}>
                        <img
                          className={styles.my_image_mask}
                          src={IMAGE_URL + outdoor_img}
                          alt="image"
                        />
                      </div>
                      {outdoor.map(
                        (item, index) =>
                          item?.image && (
                            <div key={index} className={`carousel-item `}>
                              <img
                                className={styles.my_image_mask}
                                src={IMAGE_URL + item.image}
                                alt="image"
                              />
                            </div>
                          )
                      )}
                    </>
                  )}
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#out_door_modal"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#out_door_modal"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Speakeasy */}
      <div className={styles.aboutOrganizeEventConatiner}>
        <div className="container">
          <div className={styles.Heading}>
            <div>
              <h1>Speakeasy</h1>
            </div>
            <div className={styles.read_more_less}>
              <div ref={contentRef} className={contentClass}>
                <p>
                  {!isExpanded &&
                    "On the opposite bank lies the speakeasy, discreetly concealed behind a crafty artwork-framed door. Inside, it's a captivating shift in ambience where the dark allure of..."}
                  {isExpanded &&
                    "On the opposite bank lies the speakeasy, discreetly concealed behind a crafty artwork-framed door. Inside, it's a captivating shift in ambience where the dark allure of yesteryear mingles with contemporary sophistication. Dark and mysterious, it exudes an air of refinement. Brass finishes adorn the main bar niches, pencil tiles blend with handmade tiles on the walls, and a stunning library beckons. Marble inlay flooring and intricate details grace every corner, while vaulted ceilings with linear tube lights create a sense of grandeur. Tan leather sofas and green upholstery patterned chairs offer a plush seating experience,   complemented by retro-styled bar stools. "}
                  {!isExpanded && (
                    <button
                      onClick={toggleReadMore}
                      className={styles.read_more_read_less_}
                    >
                      Read More
                    </button>
                  )}
                  {isExpanded && (
                    <button
                      onClick={toggleReadMore}
                      className={styles.read_more_read_less_}
                    >
                      Read Less
                    </button>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {speakeasy &&
                  speakeasy.map((item, index) => (
                    <div className={styles.leftContainer} key={index}>
                      {item.videoUrl ? (
                        <video controls className={styles.left_conatiner_video}>
                          <source src={item.videoUrl} type="video/mp4" />
                        </video>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
              </div>
              <div className={`col-md-6 ${styles.about_us_scroll_container}`}>
                <div
                  id="speakeasy"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  {speakeasy && speakeasy.length > 0 && (
                    <Carousel>
                      {speakeasy.map(
                        (item, index) =>
                          item?.image && (
                            <Carousel.Item key={index}>
                              <img
                                className="d-block w-100"
                                src={IMAGE_URL + item.image}
                                alt="image"
                                onClick={() => {
                                  setcabana_img(item.image);
                                  handle_speaksy();
                                }}
                              />
                            </Carousel.Item>
                          )
                      )}
                    </Carousel>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        visible={show}
        id="speaksy_model"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Speakeasy</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div
                id="speakesy_pop_modal"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  {speakeasy && speakeasy.length > 0 && (
                    <>
                      <div key={`index`} className={`carousel-item active`}>
                        <img
                          className={styles.my_image_mask}
                          src={IMAGE_URL + cabana_img}
                          alt="image"
                        />
                      </div>
                      {speakeasy.map(
                        (item, index) =>
                          item?.image && (
                            <div key={index + 1} className={`carousel-item`}>
                              {/* Use a unique key for each item in the loop */}
                              <img
                                className={styles.my_image_mask}
                                src={IMAGE_URL + item.image}
                                alt="image"
                              />
                            </div>
                          )
                      )}
                    </>
                  )}
                </div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#speakesy_pop_modal"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#speakesy_pop_modal"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutOrganizeEvent;
