// Navbar.js
import React, { useState ,useEffect} from "react";
import { Link } from "react-router-dom";
import styles from "./header.module.css";
import logo from "../Images/Logo.svg";
import PopUPModal from "./PopUpModal";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import ImageMask from "../Images/ImageMask.png";
import ImageMask1 from "../Images/Maskimg1.png";
import ImageMask2 from "../Images/Maskimg2.png";
import { IMAGE_URL } from "../../config/constant"

const navItems = [
  {
    name: "home",
    url: "/",
  },
  {
    name: "About Us",
    url: "/aboutus",
  },
  {
    name: "menu",
    url: "/",
  },
  {
    name: "gallery",
    url: "/gallery",
  },
  {
    name: "events",
    url: "/events",
  },
  {
    name: "reservation",
    url: "/",
  },
  {
    name: "contact us",
    url: "/contactus",
  },
];

const Navbar = ({ state, changeState }) => {
  // My pdf file content
  const pdfUrl = `${IMAGE_URL}foodPdf/87nPJV drink.pdf`;
  const pdfUrl1 = `${IMAGE_URL}foodPdf/jRBjcv food.pdf`;
  const pdfUrl2 = `${IMAGE_URL}foodPdf/JcWlys speakasy.pdf`;

  const handleOpenPdfCabanas = () => {
    window.open(pdfUrl, "_blank");
  };
  const handleOpenPdfOutDoors = () => {
    window.open(pdfUrl1, "_blank");
  };
  const handleOpenpdfSpeakEasy = () => {
    window.open(pdfUrl2, "_blank");
  };

  const [modalOpen, setPopUpModalOpen] = useState({
    thermalModal: false,
  });
  const [isMenuHovered, setMenuHovered] = useState(false);
  const closeMenu = () => {
    if (isMenuHovered) {
      setMenuHovered(false);
    }
  };
  
  useEffect(() => {
    document.body.addEventListener("click", closeMenu);
  
    return () => {
      document.body.removeEventListener("click", closeMenu);
    };
  }, [isMenuHovered]);

  return (
    <>
      <header
        className={`${styles.outerContainerNav} ${
          state === true ? styles.showNav : styles.hideNav
        }`}
      >
        <div className={styles.innerContainer}>
          <Link to="/">
            <img src={logo} alt="Notorious" className={styles.logo} />
          </Link>
          <div className={styles.headerBtn}>
            <button
              className={styles.resBtn}
              onClick={() => setPopUpModalOpen({ thermalModal: true })}
            >
              Reservation
            </button>
            <button className={styles.navbarCloseBtn} onClick={changeState}>
              X
            </button>
          </div>
        </div>
        {/* nav */}
        <nav className={styles.navContainer} id={styles.drop_down_header}>
          <div className={styles.navLinks}>
            {navItems?.map((item) => (
              item.name==='reservation'? <span  style={{cursor:"pointer"}} onClick={() => setPopUpModalOpen({ thermalModal: true })} >{item.name}
              </span> :          <Link
                to={item.url}
                key={item.name}
                onMouseEnter={() => setMenuHovered(item.name === "menu")}
                // onMouseLeave={() => setMenuHovered(false)}
              >
                {item.name}
              </Link>
            ))}
            {isMenuHovered && (
              <div className={styles.fullWidthDropDown}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-4">
                      <div className={styles.image_Mask} onClick={handleOpenPdfCabanas}>
                        <img src={ImageMask} alt="imagMask" />
                        <p
                          className={styles.image_mask_paragraph}
                          onClick={handleOpenPdfCabanas}
                        >
                         Drinks Menu
                        </p>
                        {/* <div className={styles.heroHeadingBtn_mask_button}>
                          <button type="button" className={styles.resBtn}  >
                            View Menu
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.image_Mask}  onClick={handleOpenPdfOutDoors}>
                        <img src={ImageMask1} alt="imagMask" />
                        <p className={styles.image_mask_paragraph} onClick={handleOpenPdfOutDoors}>
                         Food Menu
                        </p>
                        {/* <div className={styles.heroHeadingBtn_mask_button}>
                          <button
                            type="button"
                            className={styles.resBtn}
                           
                          >
                            View Menu
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={styles.image_Mask}    onClick={handleOpenpdfSpeakEasy}>
                        <img src={ImageMask2} alt="imagMask" />
                        <p className={styles.image_mask_paragraph}  onClick={handleOpenpdfSpeakEasy}>
                        Speakeasy Menu
                        </p>
                        {/* <div className={styles.heroHeadingBtn_mask_button}>
                          <button
                            type="button"
                            className={styles.resBtn}
                         
                          >
                            View 
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
      </header>
      <PopUPModal modalOpen={modalOpen} setPopUpModalOpen={setPopUpModalOpen} />
    </>
  );
};

export default Navbar;