import React, { useState ,useEffect} from 'react'
import Carousels1 from "./Images/carouselspic1.png";
import Carousels2 from "./Images/Group 4.png";
import Carousels3 from "./Images/Group 5.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousels.css";
import { ApiBaseUrl,IMAGE_URL } from '../config/constant';
import axios from 'axios'
// import { Carousel } from "bootstrap";
import Carousel from "react-bootstrap/Carousel";
function Crousel() {
  const [activeIndex, setActiveIndex] = useState(0)
  const [StoryBehind, setStoryBehind] = useState(null)
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiBaseUrl + 'home/stories')
        console.log(response.data);
        setStoryBehind(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  const initialText =
  "I had an absolutely amazing dining experience at Notorious! From the moment I stepped in, I was impressed. The ambiance is top-notch, and the service is exceptional. But the real star of the show is the food – every dish we tried was bursting with flavor and beautifully presented. Notorious is a must-visit for anyone who appreciates great food and a fantastic dining atmosphere. I can't wait to return for another unforgettable meal.";

const [isExpanded, setIsExpanded] = useState(false);

const toggleReadMore = () => {
  setIsExpanded(!isExpanded);
};
function stripHtmlTags(htmlString) {
  return htmlString.replace(/<[^>]*>/g, "");
}
const displayText = isExpanded ? initialText : initialText.substring(0, 130) + '...';

  return (
    <>
      <div className="home_crousel_container">
        <div className="container">
          <div className="home_crousel_heading">
            <p className="we_have">
              <span className="notorious_head">  {StoryBehind &&
                            StoryBehind[0]?.title &&
                            StoryBehind[0].title.split(' ')[0]}
                        </span>{' '}
                        {StoryBehind &&
                          StoryBehind[0]?.title &&
                          StoryBehind[0].title.split(' ')[1]
                          }{' '}
                          {StoryBehind &&
                          StoryBehind[0].title.split(' ')[2]
                          }
            </p>
            <p className="notoriopus_heading_p">
              It's a place where hidden treasures await, where every corner
              tells a story, and where the fusion of classic and contemporary
              design creates a timeless allure. Step into 'Notorious' and
              experience a world where imagination takes centre stage."
            </p>
          </div>
          <div className="slider_outer">
            <span className="slider_container_rightSide"></span>
            <span className="slider_container_topSide"></span>
            <span className="slider_container_leftSide"></span>
            <span className="slider_container_bottomSide"></span>
            <Carousel interval={5000}>
           {StoryBehind &&
              StoryBehind.map((story, index) => (
                <Carousel.Item key={index}>
                  <div className="slider_container">
                    <div className="slider_container_left">
                      <img src={IMAGE_URL + story.image} alt="story" />
                    </div>
                    <div className="slider_container_right">
                      <div>
                        <span className="notorious_story">{story.title}</span>
                        <p className="notorious_paragraph">
                          {isExpanded
                            ? stripHtmlTags(story.description)
                            : stripHtmlTags(story.description).length > 130
                            ? stripHtmlTags(story.description).substring(0, 130) + '...'
                            : stripHtmlTags(story.description)}
                          {stripHtmlTags(story.description).length > 130 && (
                            <button
                              className="read_more_button"
                              onClick={toggleReadMore}
                            >
                              {isExpanded ? 'Read Less' : 'Read More'}
                            </button>
                          )}
                          <br />
                          <span>{story.name}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              ))}

             {/*} <Carousel.Item>
                <div className="slider_container">
                  <div className="slider_container_left">
                    <img src="" alt="logo" />
                  </div>
                  <div className="slider_container_right">
                    <div >
                      <span className="notorious_story">Notorious Story </span>
                      <p className="notorious_paragraph">
                      Best food & cocktails in this part of the country ! staff is friendly ….. ambience is great
The whole place is very tastefully done .  👍👍 <br></br><span>  Abhay Singh Thakur</span>
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="slider_container">
                  <div className="slider_container_left">
                    <img src={Carousels2} alt="logo" />
                  </div>
                  <div className="slider_container_right">
                    <div>
                    <span className="notorious_story">Notorious Story </span>
                    <p className="notorious_paragraph">
                    Loved the rooftop & the speakeasy, food quality is excellent and drinks are addictive.
I love the press of the food and the extra effort by the staff.
A special thanks to Mr. Promoth for the wonderful service.
Surely coming back again. <br></br> <span>Sonia Parakh</span>
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="slider_container">
                  <div className="slider_container_left">
                    <img src={Carousels3} alt="logo" />
                  </div>
                  <div className="slider_container_right">
                    <div>
                    <span className="notorious_story">Notorious Story </span>
                    <p className="notorious_paragraph"> {displayText}    {initialText.length > 130 && (
        <button className="read_more_button" onClick={toggleReadMore}>
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}<br></br><span>Gurleen Sandhu
      </span> </p><br></br>
   
                    </div>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="slider_container">
                  <div className="slider_container_left">
                    <img src={Carousels3} alt="logo" />
                  </div>
                  <div className="slider_container_right">
                    <div>
                    <span className="notorious_story">Notorious Story </span>
                    <p className="notorious_paragraph">
                    Literally the best place in town! Excellent food, the most amazing cocktails and wonderful vibe. Only coming here from now on! Must visit, you won’t wanna go anywhere else!.<br></br>
                    <span>Agrima Kohli</span>
                      </p>
                    </div>
                  </div>
                </div>
                    </Carousel.Item>*/}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default Crousel;
