import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import styles from "./AboutUs.module.css";
// import nlogo from "/images/nlogo.svg";
import AboutUsStory from "./AboutUsStory";
// import OrganizeEvent from "../OrganizeEvent";
import Footer from "../footer/Footer";
import AboutOrganizeEvent from "./AboutOrganizeEvent";
import Sliderimg from "./Sliderimg";
// import AboutusBackgroundPic from "../Images/AboutUsRec3.png";
import axios from "axios";
import { ApiBaseUrl } from "../../config/constant";
// import AboutUsCrousal from "./AboutUsCarousels";

function AboutUs() {
const [storyBehindNotorious,setStoryBehindNotorious]=useState('')
const [speakeasy,setSpeakeasy]=useState('')
const [outdoor,setOutdoor]=useState('')
const [cabanas,setCabanas]=useState('')

  useEffect(()=>{
const fetchAbout= async()=>{
const res = await axios.get(ApiBaseUrl+'aboutUs')
setStoryBehindNotorious(res.data.storyBehindNotorious)
setCabanas(res.data.cabanas)
setOutdoor(res.data.outdoor)
setSpeakeasy(res.data.speakeasy)
window.scrollTo(0, 0);
}
fetchAbout()
  },[])
  return (
    <>
      <Header />
     
      <AboutUsStory storyBehindNotorious={storyBehindNotorious}/>
      <AboutOrganizeEvent  cabanas={cabanas} outdoor={outdoor} speakeasy={speakeasy}/>
     
      <Footer />
    </>
  );
}

export default AboutUs;
