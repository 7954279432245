import React,{useEffect, useState} from "react";
import Header from "../header/Header";
import styles from "./ContactUs.module.css";
// import nlogo from "/images/nlogo.svg";
import ConnectWithUs from "./ConnectWithUs";
import ConnectWithMap from "./ConnectWithMap";
import Footer from "../footer/Footer";
// import ContactUsPic from "../Images/ContactUsPic.png";
import PopUPModal from "../header/PopUpModal";

function ContactUs() {
  const [modalOpen, setPopUpModalOpen] = useState({
    thermalModal: false,
  });



  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <Header />
      <div className={styles.main_container}>
        <div className={styles.event_child_container}>
          <div className={styles.my_event_picture}>
            <img
              src={process.env.PUBLIC_URL+"/images/ContactUsPic.png"}
              alt="EventPage"
              className={styles.image_event_main_conatiner}
            />
          </div>
          <div className={styles.child_container}>
            <div className={styles.notorious_event_page_button}>
              <h1 className={styles.aboutLeftText_contact_us}>
                <span>Contact</span> Us
              </h1>
              <div className={styles.event_Paragraph}>
                <p>
                We're thrilled to connect with you and hear more about your Experiences, Inquiries, and Feedback. 
                <br />
                Our commitment to providing an exceptional dining experience extends beyond our tables, reaching every aspect of our service.
                </p>
              </div>
              <div className={styles.event_page_button}>
                <button
                  className={styles.resBtn}
                  type="button"
                  onClick={() => setPopUpModalOpen({ thermalModal: true })}
                >
                  Reservation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConnectWithUs />
      <ConnectWithMap />
      <Footer />
      <PopUPModal modalOpen={modalOpen} setPopUpModalOpen={setPopUpModalOpen} />
    </>
  );
}

export default ContactUs;
