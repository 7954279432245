import { useState, useEffect } from "react";
import styles from "./ConnectWithUs.module.css";
import { CiLocationOn } from "react-icons/ci";
import rectangle from "../Images/Contact1.png";
import {FaWhatsapp } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { CgMail } from "react-icons/cg";
import { IoLocationSharp } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { Input, InputNumber } from "antd";
import axios from "axios";
import { ApiBaseUrl, contactform,enquiryReason,contactUsaddress} from "../../config/constant";
import { ToastContainer, toast } from 'react-toastify';

function ConnectWithUs() {

  const [enquiryReasontList, setenquiryReasonList] = useState([]); 
  const [contactaddress, setcontactaddress] = useState([]); 
  const [successMessage, setSuccessMessage] = useState('');
  const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    mobile: '',
    message: '',
    enquiryReason:'',
  });
  const validateMobile = (value) => {
    const mobileRegex = /^[1-9][0-9]{9}$/; // 10 digits, not starting with 0
    return mobileRegex.test(value);
  };
  const [errors, setErrors] = useState({
    full_name: '',
    email: '',
    mobile: '',
    enquiryReason:'',
  });
 
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setSuccessMessage("");
      let newErrors = {};
      if (!formData.full_name.trim()) {
        newErrors.full_name = ' * Full Name is required';
      }
      if (!formData.email.trim()) {
        newErrors.email = ' * Email is required';
      }
      if (!formData.mobile.trim()) {
        newErrors.mobile = ' * Mobile is required';
      }
      if (!formData.enquiryReason.trim()) {
        newErrors.enquiryReason = ' * Inquiry reason is required';
      }
      if (!formData.message.trim()) {
        newErrors.message = ' * Message is required';
      } else if (formData.message.length < 6) {
        newErrors.message = ' * Message must be at least 6 characters';
      } else if (formData.message.length > 500) {
        newErrors.message = ' * Message cannot exceed 500 characters';
      }
  
      // Set the new errors state
      setErrors(newErrors);
  
      // Check if there are any validation errors
      if (Object.keys(newErrors).length > 0) {
        return;
      }
      const requestData = {
        fullName: formData.full_name,
        email: formData.email,
        mobileNumber: formData.mobile,
        message: formData.message,
        enquiryReason: formData.enquiryReason,
      };
      const response = await axios.post(ApiBaseUrl + contactform, requestData);
      // setSuccessMessage('Form submitted successfully');
      toast.success('Form submitted successfully!');
      setFormData({
        full_name: '',
        email: '',
        mobile: '',
        enquiryReason: '',
        message: '',
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  const fetchData = async () => {
    try {
      const response = await axios.get(ApiBaseUrl + enquiryReason); 
      console.log(response.data);
      setenquiryReasonList(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchDataaddress = async () => {
    try {
      const response = await axios.get(ApiBaseUrl + contactUsaddress); 
      setcontactaddress(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchDataaddress();
      
    }, []);
  return (
    <>
      <div className={`container-fluid ${styles.myConatctUs_class}`}>
      <ToastContainer />
        <div className="container">
          <div className="row">
            <div className={styles.rectangleOutline}>
              <fieldset className={styles.person1}>
                <legend className={styles.person}>
                  <div className={styles.connect_with_Us}>
                    <p className={styles.contact_us}>
                      If You Have A Query, Please Feel Free To
                    </p>
                    <span>Connect With Us.</span>
                  </div>
                </legend>

                <div className={styles.main_conatiner}>
                  {/* <div className="col-sm-2"> */}
                  <div className={styles.my_conatact_info_col}>
                    <div className={styles.main_contact_info}>
                      <span className={styles.contact_info1}>CONTACT INFO</span>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* <div className="col-sm-4"> */}
                  <div className={styles.contact_us_third_slide2}>
                    <div className={styles.my_conatact_info_col}>
                      <div className={styles.my_main_contact_info}>
                        <span className={styles.contact_info2}>
                          Contact Number
                        </span>
                        <span className={styles.contact_info2_}>
                          {" "}
                          <a href="tel:+91 8734-000008"  target="_blank">
                            <FaWhatsapp />   +91 8734-000008
                          </a>{" "}
                        
                        </span>
                      </div>
                      <div className={styles.my_main_contact_info}>
                        <span className={styles.contact_info2}>
                          Email Address
                        </span>
                        <span className={styles.contact_info2_}>
                          <a href="mailto:reservations@notorious.co.in"  target="_blank">
                            {" "}
                            <CgMail />    reservations@notorious.co.in
                          </a>{" "}
                        
                        </span>
                      </div>
                      <div className={styles.my_main_contact_info}>
                        <span className={styles.contact_info2}>
                          Office Address
                        </span>
                        <span className={styles.contact_info2_location_sharp}>
                          <a href="https://maps.app.goo.gl/akx94acwUv4tvu5b8"  target="_blank">
                            <CiLocationOn /> 5th Floor One Park Side, 334, Shastri Nagar, Lajpat Nagar, Jalandhar, Punjab 144001    
                          </a>{" "}
                      
                        </span>
                      </div>
                      <div className={styles.my_main_contact_info}>
                        <span className={styles.contact_info2}>
                          Follow Us On
                        </span>
                        <span className={styles.contact_info2_1_icon}>
                         
                          <a href="https://www.instagram.com/notorious.jtown/?hl=en">
                            <FaInstagram />
                          </a>
                          <a href="/">
                            <FiFacebook />
                          </a>
                          {/* <a href="/">
                          <FaXTwitter />
                          </a> */}
                         
                        </span>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* <div className="col-sm-6"> */}
                  <div className={styles.contact_us_third_slide}>
                    <div className={styles.my_conatact_info_col}>
                      <div className={styles.my_contact_form_main_container}>
                        <span className={styles.my_contact_form}>
                          Contact Form
                        </span>
                        {successMessage && (
                          <div style={{
                            color: 'green',
                            fontSize: '21px',
                          }}>
                            {successMessage}
                          </div>
                        )}
                        <div className={styles.my_input_form}>
                        <form
  action=""
  className={styles.HomeFormInput_}
  autoComplete="off"
  onSubmit={handleSubmit} // Ensure this line is present
>
   <div className={styles.flex_direction}>
                    <div className={styles.inputWrapper}>
                      <input
                        type="text"
                        placeholder="Full Name"
                        className={styles.HomeFormInputField_}
                        name="full_name" 
      value={formData.full_name}
      onChange={(e) => {
        const value = e.target.value;

        // Validate full name (required)
        const MAX_NAME_LENGTH = 35;
        const MIN_NAME_LENGTH = 4;
        const nameRegex = /^[a-zA-Z\s]*$/; // Only allow letters and spaces
        let valid = true; // Initialize valid as true

        let newErrors = {}; // Create a new errors object

        if (!value.trim()) {
          newErrors.full_name = " * Name is required";
          valid = false;
        } else if (value.length < MIN_NAME_LENGTH) {
          newErrors.full_name = ` * Name must be at least ${MIN_NAME_LENGTH} characters`;
          valid = false;
        } else if (value.length > MAX_NAME_LENGTH) {
          newErrors.full_name = ` * Name cannot exceed ${MAX_NAME_LENGTH} characters`;
          valid = false;
        } else if (!nameRegex.test(value)) {
          newErrors.full_name = " * Name can only contain letters and spaces";
          valid = false;
        } else {
          newErrors.full_name = ""; // Clear the error message if the input is valid
        }

        setFormData({ ...formData, full_name: value });
        setErrors({ ...errors, full_name: newErrors.full_name });

        // Return early if input is invalid
        if (!valid) {
          return;
        }
      }}
                      />
                       {errors.full_name && (
    <span className={styles.errorMessage}>{errors.full_name}</span>
  )}
                    </div>
                    <div className={styles.inputWrapper}>
                      <input
                        type="number"
                        placeholder="Mobile Number"
                        className={styles.HomeFormInputField_}
                        name="mobile"
          value={formData.mobile}
          onChange={(e) => {
            const value = e.target.value;

            let newErrors = { ...errors };

            if (!value.trim()) {
              newErrors.mobile = ' * Mobile is required';
            } else if (!validateMobile(value)) {
              newErrors.mobile = '* Invalid mobile number';
            } else {
              newErrors.mobile = '';
            }

            setFormData({ ...formData, mobile: value });
            setErrors(newErrors);
          }}

                      />
                       {errors.mobile && (
    <span className={styles.errorMessage} >{errors.mobile}</span>
  )}
                    </div>
                    </div>

                    {/* <div className={styles.inputWrapper}> */}
                    <input
                      type="email"
                      placeholder="Email Address"
                      className={styles.HomeFormInputField_new}
                      value={formData.email}  
                      onChange={(e) => {
                         const value = e.target.value;
                         const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                     
                         let newErrors = { ...errors }; // Create a copy of existing errors
                     
                         if (!value.trim()) {
                           newErrors.email = " * Email is required";
                         } else if (value.length < 6) {
                           newErrors.email = " * Email must be at least 6 characters";
                         } else if (value.length > 100) {
                           newErrors.email = " * Email cannot exceed 100 characters";
                         } else if (!emailRegex.test(value)) {
                           newErrors.email =  " * Invalid email format";
                         } else {
                           // Clear the error message if the input is valid
                           newErrors.email = "";
                         }
                     
                         setFormData({ ...formData, email: value });
                     
                         // Update the errors state with the newErrors object
                         setErrors(newErrors);
                       }}
                    />
                    {errors.email && (
  <span className={styles.email} >{errors.email}</span>
)}
                    {/* </div> */}
                    <select
  className={styles.HomeFormSelect_}
  name="enquiryReason"
  value={formData.enquiryReason}
  onChange={(e) => {
    // Clear the error message when the dropdown value changes
    setErrors({ ...errors, enquiryReason: '' });

    setFormData({ ...formData, enquiryReason: e.target.value });
  }}
>
  <option value="" disabled defaultValue>
    Select Inquiry Reason
  </option>
  {enquiryReasontList.map((items, index) => (
    <option key={index} value={items.reason}>
      {items.reason}
    </option>
  ))}
</select>
{errors.enquiryReason && (
  <span className={styles.errorMessage} >{errors.enquiryReason}</span>
)}


                    
                    <textarea
                      placeholder="Type Your Message Here (If Any...)"
                      className={styles.HomeFormTextarea_}  value={formData.message}
                      onChange={(e) => {
                        const value = e.target.value;
          
                        let newErrors = { ...errors };
          
                        if (value.length < 6) {
                          newErrors.message = ' * Message must be at least 6 characters';
                        } else if (value.length > 500) {
                          newErrors.message = ' * Message cannot exceed 500 characters';
                        } else {
                          newErrors.message = '';
                        }
          
                        setFormData({ ...formData, message: value });
                        setErrors(newErrors);
                      }}
                    />
                     {errors.message && (
    <span className={styles.errorMessage} >{errors.message}</span>
  )}
                    <button type="submit" className={styles.SubmitButton} onClick={handleSubmit} style={{zIndex:"999"}}>
    Submit
  </button>
                  </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      {/* <div style={{backgroundColor:'red',minHeight:'525px'}}>
              <div>
                <div>dfdfgfdg</div>
              </div>
      </div> */}
    </>
  );
}
export default ConnectWithUs;
