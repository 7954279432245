import React, { useEffect, useState } from "react";
import styles from "./header.module.css";
import logo from "../Images/Logo.svg";
import hamburger from "../Images/hamburger.svg";
import Navbar from "./Navbar";
import PopUPModal from "./PopUpModal";
import { Link } from "react-router-dom";

const Header = () => {
  const [modalOpen, setPopUpModalOpen] = useState({
    thermalModal: false,
  });
  const [state, setState] = useState(false);
  const changeState = () => {
    setState((prev) => !prev);
  };

  // sticky implement
  // const [isSticky, setIsSticky] = useState(false);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setIsSticky(window.scrollY > 100);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const headerStyle = {
  //   position: isSticky ? 'fixed' : 'relative',
  //   top: 0,
  //   width: '100%',
  //   zIndex: 999999,
  // };
  // const scrollToSection = () => {
  //   const sectionId = "yourSectionId";
  //   document.getElementById(sectionId).scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };
  return (
    <div>
      {/* <header className={styles.outerContainer} style={headerStyle} > */}
      <header className={styles.outerContainer}>
        <div className={styles.innerContainer}>
          <Link to='/'>
            <img src={logo} alt="Notorious" className={styles.logo} />
          </Link>
          <div className={styles.headerBtn}>
            <button
              className={styles.resBtn}
              onClick={() => setPopUpModalOpen({ thermalModal: true })}
            >
              Reservation
            </button>
            <button className={styles.hamburgerBtn} onClick={changeState}>
              <img src={hamburger} alt="menu" />
            </button>
          </div>
        </div>
      </header>
      <Navbar state={state} changeState={changeState} />
      <PopUPModal modalOpen={modalOpen} setPopUpModalOpen={setPopUpModalOpen} />
    </div>
  );
};

export default Header;
