import React from "react";
import { Col, Modal, Row } from "antd";
import styles from "./Thanku.module.css";
import frame11 from '../Images/Frame 17.png'
// import nLogo from '../Images/notorious.png'
import { IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";

const ThankuPage = ({ thankuModalOpen, setThankuPageOpen ,reserveTable}) => {
  const handleCancel = () => {
    setThankuPageOpen((prev) => ({
      ...prev,
      myThankuModal: false,
    }));
  };

  const convertDateFormat = (inputDate) => {
    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
}
const convertTimeFormat = (inputTime) => {
 if(inputTime){
  const [hours, minutes] = inputTime.split(':');
  let formattedTime = '';

  // Convert hours to 12-hour format and determine AM or PM
  const hour = parseInt(hours, 10);
  const period = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

  // Construct the formatted time string
  formattedTime = `${formattedHour}:${minutes} ${period}`;

  return formattedTime;
 }
 else{

 }
}
  return (
    <Modal
      className={styles.tharmal_modal}
      open={thankuModalOpen?.myThankuModal}
    //   title="Estimate"
      width={600}
      wrapClassName={styles.blurBackground}
      //   bodyStyle={{ height: '100px' }}
      maskClosable={false}
      footer={null}
      onCancel={handleCancel}
    >
      <div className={styles.my_slider_page}>
        <div className={styles.my_slider_nlog_img1_frame}>
          <img src={frame11} alt="Notorious" />
        </div>
        <div className={styles.my_slide_paragraph}>
          <span> Your Reservation is confirmed. </span>
        </div>

        <div className={styles.my_slider_nlog_img1_frame}>
          <span className={styles.slider_tanku}>Thank You</span>
        </div>
        <div className={styles.booking_details}>
          <span>Booking Details:</span>
        </div>
        <div className={styles.my_square_section}>
          <div className={styles.my_background_section}>
            <div className={styles.my_background_section1}>
              <div className={styles.my_square_logo}>
              <img src={process.env.PUBLIC_URL + "./images/notorious.png"} alt="" />
                {/* <img src={nLogo} alt="Notorious" /> */}
              </div>
              <div className={styles.sliding_paragraph}>
              <span className={styles.my_date_time_paragraph} style={{marginTop:"-16px"}}>
                <Row>
                  <Col>
                  {reserveTable.fullName}   {" "}    
                  </Col>
                  <Col>
             {" "}     <IoCallSharp /> {reserveTable.mobileNumber} {" "} 
                  </Col>
                  <Col>
                  {" "}     <IoMdMail /> {reserveTable.email} {" "} 
                  </Col>
                </Row>
              </span>
                <span className={styles.my_date_time_paragraph}>
                {/* Tuesday, October 10, /time 2023 3:00:00pm*/}
                {reserveTable.noOfMembers} Person,<br /> {convertDateFormat(reserveTable?.bookingDate)}<br />
                {reserveTable?.time}
                {/* {convertTimeFormat(reserveTable?.time)} */}
                </span>
           
              </div>
            </div>
            <div className={styles.contact_details}>
              <span>Conatct Details:</span>
            </div>
            <div className={styles.location_section}>
              <div className={styles.name_section}>
                {/* <span>{reserveTable.fullName}</span> */}
              </div>
              <div className={styles.contact_number_section}>
                <span>
                  <IoCallSharp />+91 8734000008
                </span>
              </div>
              <div className={styles.contact_number_section}>
                <span>
                  <IoMdMail /> reservations@notorious.co.in
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ThankuPage;
