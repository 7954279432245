export const key = "d1b489125736b0deec10df01e8026cabcc3b49252be0c981c190a1feefa71aed";
export const ApiBaseUrl = "https://notorious.co.in/api/";
export const ADMIN_ASSETS_URL = "https://notorious.co.in/api/";
export const IMAGE_URL = "https://notorious-assets.s3.ap-south-1.amazonaws.com/";
export const events = "events";
export const contactform = "contactUs";
export const enquiryReason = "contactUs/enquiryReason";
export const contactUsaddress = "contactUs/address";



export const EVENT = {
  ACTIVE: 1,
  NOT_ACTIVE: 0,
  WAITING: 0,
  APPROVED: 1,
  DECLINED: 2,
  CANCEL_REVIEW: 1,
  CANCEL_FINAL: 2,
  FREE: 1,
  PAID: 2,
  PAID_NO_PINT: 3,
  DOOLALLY_FEE: 4,
  PRIVATE: 5,
  STATUS_YES: 1,
  STATUS_NO: 2,
};
export const EH_GATEWAY_CHARGE = 5.26;
export const DOOLALLY_GATEWAY_CHARGE = 2.29;
export const INSTAMOJO_GATEWAY_CHARGE = 3;
export const PAYTM_GATEWAY_CHARGE = 5;
export const OLD_DOOLALLY_FEE = 250;
export const NEW_DOOLALLY_FEE = 300;
export const perPageShowData = 6;
export const EVENT_START_HR = 10;
export const EVENT_END_HR = 20;


