import React,{useEffect} from "react";
import Header from "../header/Header";
import Footer from "./Footer";
import styles from "./footer.module.css";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <div className={`container-fluid ${styles.my_privacy_policy_main_class}`}>
        <div className="container">
          <div className="row">
            <div className={styles.my_main_content}>
              <div className={styles.my_privacy_policy_paragraph}>
                <p className={styles.main_paragraph}>
                  Privacy Policy for <span>Notorious</span>
                </p>
                <span className={styles.privacy_policy_date}>
                  Last Updated: 20-December-2023
                </span>
                <p className={styles.main_paragraph1}>
                  Thank you for choosing <strong> Notorious </strong>. Your
                  privacy is important to us, and we are committed to protecting
                  the personal information you share with us. This Privacy
                  Policy outlines how we collect, use, disclose, and protect
                  your information.
                </p>
              </div>

              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>
                  1. Information We Collect:
                </p>
                <span className={styles.privacy_policy_date}>
                 
                    We may collect the following types of personal information
                    when you visit our restaurant cum bar:
                 
                </span>
                <p className={styles.main_paragraph1}>
                  Contact Information: Such as your name, email address, phone
                  number, and postal address. Reservation Information: If you
                  make a reservation, we may collect details like the date,
                  time, and size of your party. Payment Information: When you
                  make a purchase, we collect payment details such as credit
                  card information. Demographic Information: We may collect
                  demographic information, such as age, gender, and preferences,
                  to enhance your experience.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>
                  2. How We Use Your Information:
                </p>
                <span className={styles.privacy_policy_date}>
                  
                    We use the collected information for various purposes,
                    including:
                 
                </span>
                <p className={styles.main_paragraph1}>
                  Reservations and Services: To fulfill reservations, provide
                  services, and process transactions. Communication: To send you
                  updates, promotional offers, and newsletters (you can opt-out
                  at any time). Improvement of Services: To enhance our
                  services, analyze customer preferences, and gather feedback.
                  Security: To protect against fraud, unauthorized access, and
                  other illegal activities.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>
                  3. Information Sharing:
                </p>
                <p className={styles.main_paragraph1}>
                  We do not sell, trade, or rent your personal information to
                  third parties. However, we may share your information with
                  trusted third parties who assist us in operating our website,
                  conducting business, or servicing you, as long as those
                  parties agree to keep your information confidential.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>4. Security:</p>
                <p className={styles.main_paragraph1}>
                  We take reasonable precautions and follow industry best
                  practices to protect your personal information. However, no
                  method of transmission over the Internet or electronic storage
                  is 100% secure. Therefore, while we strive to use commercially
                  acceptable means to protect your personal information, we
                  cannot guarantee its absolute security.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>5. Cookies:</p>
                <p className={styles.main_paragraph1}>
                  We may use cookies to enhance your experience on our website.
                  You can choose to disable cookies through your browser
                  settings, but this may affect your ability to access certain
                  features of our site.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>6. Your Choices:</p>
                <p className={styles.main_paragraph1}>
                  You have the right to access, correct, or delete your personal
                  information. You may also choose to opt-out of receiving
                  promotional communications from us.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>
                  7. Changes to Privacy Policy:
                </p>
                <p className={styles.main_paragraph1}>
                  We may update our Privacy Policy from time to time. The latest
                  version will be posted on our website, and the date of the
                  last update will be indicated.
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.information_collect}>8. Contact Us:</p>
                <p className={styles.main_paragraph1}>
                  If you have any questions or concerns about our Privacy
                  Policy, please contact us at <strong> reservations@notorious.co.in</strong> .
                </p>
              </div>
              <div className={styles.my_privacy_policy_paragraph_information}>
                <p className={styles.main_paragraph1}>
                  By using our website or services, you consent to the terms
                  outlined in this Privacy Policy. Thank you for choosing
                  <strong> Notoruious.</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
