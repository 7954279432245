import { useState, useEffect, useRef } from "react";
import styles from "./EventBy.module.css";
import axios from "axios";
import { ApiBaseUrl, events, IMAGE_URL } from "../../config/constant";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function EventBy() {
  const [eventList, setEventList] = useState([]);
  const [oureventList, setOurEventList] = useState([]);

  function formatDate(eventDate) {
    return `${eventDate} 29 Oct, 2023`;
  }

  function stripHtmlTags(htmlString) {
    return htmlString.replace(/<[^>]*>/g, "");
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(ApiBaseUrl + events);
      console.log(response.data.ourEvents);
      setEventList(response.data.events);
      setOurEventList(response.data.ourEvents);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Reinitialize Fancybox when the component updates
    NativeFancybox.bind("[data-fancybox='images']", {
      arrows: true,
      infobar: false,
      loop: true,
      buttons: ["slideShow", "fullScreen", "thumbs", "close"],
      animationEffect: "fade",
      transitionEffect: "slide",
    });

    // Cleanup when the component unmounts
    return () => {
      NativeFancybox.destroy();
    };
  }, [eventList]); // Make sure to include eventList as a dependency

  return (
    <>
      <div className={styles.event_by_main_container}>
        <div className={styles.event_by_mid_container}>
        {oureventList.map((items, index) => (
            <div key={index}>
              <h1 className={styles.aboutLeftText_events_by}>
                {items.title}
              </h1>
              <p className={styles.event_by_Paragraph}>
               {stripHtmlTags(items.description)}
              </p>
            </div>
          ))}

        </div>
        <div className="container mt-3">
          <div className="row">
            {eventList.map((items, index) => {
              const eventDate = new Date(items.eventDate);
              const options = {
                day: "numeric",
                month: "long",
                year: "numeric",
              };
              return (
                <div className="col-md-4 col-12" key={index}>
                  <div className={styles.text_overlay}>
                    <span className={styles.card_date}>
                      {eventDate.toLocaleDateString("en-US", options)}
                    </span>
                    <span className={styles.lorem_paragraph}>
                      {items.title}
                      {console.log(items, "title")}
                    </span>
                    <p className={styles.lorem_paragraph1}>
                      {stripHtmlTags(items.description)}
                    </p>
                    <a href="#">Read More...</a>

                    <div className={styles.my_contant}>
                      <div className={styles.event_module_item_description}>
                        <a
                          href={IMAGE_URL + items.image}
                          data-fancybox="images"
                          // data-caption={items.title}
                          data-caption={`<span style="display: flex;padding-inline: 20%;justify-content: center;">${items.title}</span>\n <span style="display: flex;padding-inline: 20%;">${items.description}</span>`}
                          className={styles.facny_box_img_zoom}
                        >
                          <img
                            src={IMAGE_URL + items.image}
                            alt="Image"
                            className={styles.image_rec_pic}
                          />
                        </a>
                      </div>
                      <span className={styles.card_date}>
                        {eventDate.toLocaleDateString("en-US", options)}
                      </span>
                      <span className={styles.card_paragraph}>
                        {items.title}
                      </span>
                      <a
                        href={IMAGE_URL + items.image}
                        data-fancybox={`images`}
                        data-caption={items.title}
                      >
                        Read More...
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventBy;
