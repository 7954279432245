import React, { useState, useEffect } from "react";
import styles from "./ourmenu.module.css";
import axios from "axios";
import { ApiBaseUrl, IMAGE_URL } from "../config/constant";

const OurMenu = () => {
  const [aboutMenu, setAboutMenu] = useState(null);
  const [foodMenu, setFoodMenu] = useState(null);
  const pdfUrl = `${IMAGE_URL}foodPdf/87nPJV drink.pdf`;
  const pdfUrl1 = `${IMAGE_URL}foodPdf/jRBjcv food.pdf`;
  const pdfUrl2 = `${IMAGE_URL}foodPdf/JcWlys speakasy.pdf`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(ApiBaseUrl + "home/menu");
        setFoodMenu(response.data.foodMenu);
        setAboutMenu(response.data.AboutMenu);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleOpenPdfCabanas = (name) => {
    if(name.includes('Food')){
      window.open(pdfUrl1, "_blank");
    }else if(name.includes('Speakeasy')){
      window.open(pdfUrl2, "_blank");
    } else{
      window.open(pdfUrl, "_blank");
    }
  };

  return (
    <section className={styles.outerContainer}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <div className={styles.innerContainer}>
              <div className={styles.leftSection}>
                {foodMenu &&
                  foodMenu.map((item, index) => (
                    <div className={styles.shade} key={index}>
                      <div
                        className={styles.postion_OurMenu}
                        onClick={() => handleOpenPdfCabanas(item.title)}
                      >
                        <img
                          src={
                            item.image
                              ? IMAGE_URL + item.image
                              : process.env.PUBLIC_URL + "./images/img4.png"
                          }
                          alt=""
                          className={styles.leftImg1}
                        />
                        <span>{item.title ? item.title : "Menu Item "}</span>
                      </div>
                    </div>
                  ))}
              </div>
              <div className={styles.rightSection}>
                <div className={styles.Heading}>
                  <h4 className={styles.Heading1}>
                    {" "}
                    {aboutMenu && aboutMenu[0].title.split(" ")[0]}{" "}
                    {aboutMenu && aboutMenu[0].title.split(" ")[1]}{" "}
                  </h4>
                  <h4 className={styles.subheading}>
                    {aboutMenu &&
                      aboutMenu[0].title.split(" ").slice(2).join(" ")}
                  </h4>
                </div>
                <div className={styles.textContent}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: aboutMenu && aboutMenu[0]?.description,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMenu;
