import React from "react";
import styles from "./ConnectWithMap.module.css";

function ConnectWithMap() {
  return (
    <>
      <div className={styles.contact_us_main_class}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-4">
              <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1704.253126644441!2d75.5748074382914!3d31.317389084993493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5b214abe6191%3A0x93b5f1afa54ad4fb!2sONE%20PARK%20SIDE!5e0!3m2!1sen!2sin!4v1703310460873!5m2!1sen!2sin"
                width="100%"
                height="600px"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectWithMap;
