import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./mobile_gallery.css";
const Gallerymobile = () =>{
  
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,       
        autoplaySpeed: 2000,
      };
    
    return(
        <>
          
        <Slider {...settings}>
      
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle18.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle19.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle20.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle21.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle22.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle23.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle24.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle25.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle26.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle27.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle21.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle29.png"} alt="app" />
      </div>
      <div className="gallery_slider">
      <img src={process.env.PUBLIC_URL + "./images/galleryimages/Rectangle30.png"} alt="app" />
      </div>

      <div>
       
       
      </div>
      {/* Add more slides as needed */}
    </Slider>
        </>
    )
}
export default Gallerymobile;