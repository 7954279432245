// utils/formValidation.js
import * as Yup from 'yup';

const vendorRegistrationSchema = Yup.object({
  fullName: Yup.string().required('Full Name is required'),
  mobileNumber: Yup.string().required('Mobile Number is required').matches(/^\d+$/, 'Mobile Number must be a valid number'),
  email: Yup.string().email('Invalid email address').required('Email Address is required'),
  noOfMembers: Yup.number().required('Number of Members is required').positive('Number of Members must be positive'),
  bookingDate: Yup.string().required('Please Select Date'),
  occasion: Yup.string().required('Occasion For is required'),
//   Date: Yup.string().required('Date For is required'),
  time: Yup.string().required('Please Select Time'),
//   message: Yup.string().required('Occasion For is required')
  // Add validation rules for other fields as needed
});

const validateForm = (formData, checkBlur) => {
  return new Promise((resolve, reject) => {
    vendorRegistrationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        if (checkBlur) {
          // Additional logic when checkBlur is true
          // For example, setBlur(true);
        }
        resolve();
      })
      .catch((errors) => {
        // Handle validation errors here
        reject(errors);
      });
  });
};


export { vendorRegistrationSchema, validateForm };
